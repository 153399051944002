.layout{
    background-color: white;
    color:green;
    height:100vh;
    width:100vw;
}
.body{
    background-color: white;
    color:green;
    min-height:93vh;
    width:100vw;
    margin-top: -3vh;
    border-top-left-radius: 4%;
    border-top-right-radius: 4%;
}
.container{
    display:flex;
    gap:0;
}